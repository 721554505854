import bootstrapSite from '../app/bootstrapSite';

bootstrapSite();

window.addEventListener('load', () => {
    const typeformContainer = document.querySelector('.j-typeform-widget');

    if (typeformContainer) {
        let { typeformId } = typeformContainer.dataset;
        const { url } = typeformContainer.dataset;

        if (!typeformId && url) {
            [, typeformId] = /to\/([a-zA-Z0-9]+)\/?/.exec(url);
        }

        if (!typeformId) {
            throw Error('typeform ID not defined. Please update the container div to have \'data-typeformId="typeform_ID"\'');
        }

        import(/* webpackChunkName: "typeformEmbed" */ '@typeform/embed').then((typeformEmbed) => {
            typeformEmbed.createWidget(typeformId, {
                autoResize: true,
                container: typeformContainer,
            });
        });
    }
});
